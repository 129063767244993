import React, { useContext } from "react";
import { AppContext } from "../../Router";
import { useLocation } from "react-router-dom";

const Navigation = () => {
  const { user, userAttributes, signOut, partner } = useContext(AppContext);
  const location = useLocation();

  const pathname = location.pathname;

  const handleSignOut = (event) => {
    event.preventDefault();
    signOut();
  };

  return (
    <nav>
      <ul className="nav-list">
        {userAttributes.admin && (
          <>
            <li>
              <a
                href="/"
                className={pathname === "/" ? "active" : ""}
                aria-current="page"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="/menus"
                className={pathname === "/menus" ? "active" : ""}
                aria-current="page"
              >
                Menus
              </a>
            </li>
          </>
        )}
        {partner?.config?.ordering && (
          <li>
            <a
              href="/orders"
              className={pathname === "/orders" ? "active" : ""}
              aria-current="page"
            >
              {" "}
              Orders
            </a>
          </li>
        )}
      </ul>
      {user && (
        <a
          data-test-id="logout-link"
          className="logout-button"
          href="/"
          onClick={handleSignOut}
          aria-current="page"
        >
          Logout
        </a>
      )}
    </nav>
  );
};

export default Navigation;
