import React, { useContext } from "react";
import { capitalizeText, getDisplayPrice } from "../../utils";
import { getLocalTime } from "../../utils/query";
import { AppContext } from "../../Router";
import "./index.css";

const LatestOrders = ({ orders }) => {
  const { partner } = useContext(AppContext);
  const latestOrders = [...orders];

  const getOrderTotal = (order) => {
    return order.sales.reduce(
      (acc, curr) => acc + parseInt(curr.price, 10) * curr.quantity,
      0,
    );
  };

  const renderOrders = latestOrders
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map((order) => {
      const { id, orderNumber, orderSource, createdAt, sales, status } = order;
      return (
        <tr key={id}>
          {/* <td>#{orderNumber}</td>
          <td>{`${capitalizeText(orderSource.type)} ${orderSource.name}`}</td>
          <td>
            {getDisplayPrice({
              priceInPence: getOrderTotal(order),
              withCurrencySymbol: true,
              currencyConfig: partner.config.currencyConfig,
            })}
          </td>
          <td>{getLocalTime(createdAt)}</td> */}
          <td data-label="Order ID">#{orderNumber}</td>
          <td data-label="Location">{`${capitalizeText(orderSource.type)} ${orderSource.name}`}</td>
          <td data-label="Items">{sales.length} items</td>
          <td data-label="Total">
            {" "}
            {getDisplayPrice({
              priceInPence: getOrderTotal(order),
              withCurrencySymbol: true,
              currencyConfig: partner.config.currencyConfig,
            })}
          </td>
          <td data-label="Time">{getLocalTime(createdAt)}</td>
          <td data-label="Status">
            <span className={`status ${status}`}>{status}</span>
          </td>
        </tr>
      );
    });

  return (
    <>
      <section className="recent-orders">
        <header className="section-header">
          <h2>Recent Orders</h2>
          <a href="/orders" className="view-all">
            View All
          </a>
        </header>
        {latestOrders.length > 0 && (
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Location</th>
                <th>Items</th>
                <th>Total</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{renderOrders}</tbody>
          </table>
        )}
        {latestOrders.length === 0 && (
          <p className="text_center">No orders yet today</p>
        )}
      </section>
    </>
  );
};

export default LatestOrders;
