import React, { useState } from "react";
import "./OrderDetails.css";
import { getLocalTime, formatDate } from "../../utils/query";
import { statuses, capitalizeText } from "../../utils/";

const OrderDetails = ({ order, updateOrder, updatePaymentStatus }) => {
  const [expanded, setExpanded] = useState(true);
  let actions = [];
  const getMarkPaid = () => {
    return (
      <div className="mark-paid">
        <input
          type="checkbox"
          id="markPaid"
          checked={order.paymentDetails.status === "Paid"}
          onChange={() =>
            updatePaymentStatus(
              order.id,
              order.paymentDetails.status === "Paid" ? "Unpaid" : "Paid",
            )
          }
        />
        <label htmlFor="markPaid">
          Mark as {order.paymentDetails.status === "Paid" ? "Unpaid" : "Paid"}
        </label>
      </div>
    );
  };
  if (order.status === statuses.PLACED) {
    actions = [
      <button
        key={`${order.id}-1`}
        type="button"
        className="btn btn-reject"
        onClick={() => updateOrder(order.id, statuses.CANCELLED)}
      >
        Cancel
      </button>,
      <button
        key={`${order.id}-2`}
        type="button"
        className="btn btn-accept"
        onClick={() => updateOrder(order.id, statuses.CONFIRMED)}
      >
        Confirm
      </button>,
    ];
  } else if (order.status === statuses.CONFIRMED) {
    actions = [
      <button
        key={`${order.id}-4`}
        className="btn btn-reject"
        onClick={() => updateOrder(order.id, statuses.CANCELLED)}
      >
        Cancel order
      </button>,
      <button
        key={`${order.id}-3`}
        className="btn btn-transit"
        onClick={() => updateOrder(order.id, statuses.TRANSIT)}
      >
        Order in transit
      </button>,
    ];
  } else if (order.status === statuses.TRANSIT) {
    actions = [
      <button
        key={`${order.id}-5`}
        className="btn btn-delivered"
        onClick={() => updateOrder(order.id, statuses.DELIVERED)}
      >
        {" "}
        Order delivered
      </button>,
    ];
  } else {
    actions = [];
  }
  return (
    <article className={`order-card  ${expanded ? "expanded" : ""}`}>
      <div className="order-card-header" onClick={() => setExpanded(!expanded)}>
        <h3 className="order-title">Order #{order.orderNumber}</h3>
        <span className="order-amount">
          ₦{(order.paymentDetails.amount / 100).toFixed(2)}
        </span>
        <span className={`order-status status-${order.status}`}>
          {capitalizeText(order.status)}
        </span>
        <button
          type="button"
          className="expand-button"
          aria-label="Toggle order details"
        >
          <svg
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>
      </div>

      <div className="order-card-content">
        <div className="order-details">
          <div className="order-meta">
            <div className="order-meta-item">
              <span className="meta-label">Order Date</span>
              <span className="meta-value">{formatDate(order.createdAt)}</span>
            </div>
            <div className="order-meta-item">
              <span className="meta-label">Order Time</span>
              <span className="meta-value">
                {getLocalTime(order.createdAt)}
              </span>
            </div>
          </div>

          <div className="order-items">
            <h4 className="order-items-title">Order Items</h4>
            <div className="item-list">
              {order.sales.map((item, index) => (
                <div className="order-item" key={index}>
                  <span className="item-name">{capitalizeText(item.name)}</span>
                  <span className="item-quantity">× {item.quantity}</span>
                  <span className="item-price">
                    {" "}
                    ₦{(item.price / 100).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="order-summary">
            <div className="summary-section">
              <h4 className="summary-title">Payment Details</h4>
              <div className="summary-content">
                <div className="summary-item">
                  <span className="summary-label">Amount</span>
                  <span className="summary-value">
                    ₦{(order.paymentDetails.amount / 100).toFixed(2)}
                  </span>
                </div>
                <div className="summary-item">
                  <span className="summary-label">Method</span>
                  <span className="summary-value">
                    {String(order.paymentDetails.method || "").toUpperCase()}
                  </span>
                </div>
                <div className="summary-item">
                  <span className="summary-label">Status</span>
                  <span className="summary-value">
                    {capitalizeText(order.paymentDetails.status)}
                  </span>
                </div>
                {getMarkPaid()}
              </div>
            </div>

            <div className="summary-section">
              <h4 className="summary-title">Customer Details</h4>
              <div className="summary-content">
                <div className="summary-item">
                  <span className="summary-label">Name</span>
                  <span className="summary-value">
                    {capitalizeText(order.customerDetails.name)}
                  </span>
                </div>
                {order.customerDetails.tableNumber && (
                  <div className="summary-item">
                    <span className="summary-label">Table</span>
                    <span className="summary-value">
                      {order.customerDetails.tableNumber}
                    </span>
                  </div>
                )}
                {order.customerDetails.roomNumber && (
                  <div className="summary-item">
                    <span className="summary-label">Room</span>
                    <span className="summary-value">
                      {order.customerDetails.roomNumber}
                    </span>
                  </div>
                )}
                <div className="summary-item">
                  <span className="summary-label">Section</span>
                  <span className="summary-value">
                    {capitalizeText(order.orderSource.sectionName)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="order-actions">{actions}</div>
        </div>
      </div>
    </article>
  );
};

export default OrderDetails;
