import React from "react";
import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { getDisplayPrice } from "../../../../utils";
import { capitalizeText } from "../../../../utils";

const MenuModal = ({
  handleCancel,
  handleOk,
  selectedMenu,
  confirmLoading,
  currencyConfig,
}) => {
  const [form] = Form.useForm();
  const menuFields = ["name", "description"];

  if (selectedMenu.locationPrices) {
    menuFields.push("locationPrices");
  } else {
    menuFields.push("price");
  }
  const getMenuPrices = (values) => {
    return Object.keys(values).reduce((acc, key) => {
      if (key.includes("locationPrice")) {
        const location = key.substring(14);
        const price = convertToPence(values[key]);
        acc.push({
          location,
          price, // convert to pence or kobo
        });
      }
      return acc;
    }, []);
  };
  const onFinish = (values) => {
    const updatedFields = menuFields.reduce((acc, key) => {
      if (key === "locationPrices") {
        acc.locationPrices = getMenuPrices(values);
      } else {
        acc[key] = key === "price" ? convertToPence(values[key]) : values[key];
      }
      return acc;
    }, {});
    const updatedValues = {
      ...selectedMenu,
      ...updatedFields,
    };

    const { locationPrices = [] } = updatedValues;
    if (locationPrices && locationPrices.length > 0) {
      const bushBarPrice = locationPrices.find(
        (locationPrice) => locationPrice.location === "bush-bar", // set price to bush-bar price as backward compatibility
      );

      if (bushBarPrice) {
        updatedValues.price = bushBarPrice.price; // set price to bush-bar price as backward compatibility
      }
    }

    handleOk({
      ...updatedValues,
    });
  };
  const convertToPence = (value) => String(Number(value) * 100);
  const currencySymbol = currencyConfig.symbol || "";

  const renderLocationPriceItem = (locationPrice, index) => {
    const initialValue = getDisplayPrice({
      priceInPence: locationPrice.price,
      withCurrencySymbol: false,
      currencyConfig,
    });
    return (
      <Form.Item
        key={`prices-${index}`}
        label={capitalizeText(`${locationPrice.location}`)}
        name={`locationPrice-${locationPrice.location}`}
        initialValue={initialValue}
        data-test-id="menu-edit-form-input"
        rules={[{ required: true, message: "Please input prices!" }]}
      >
        <Input
          prefix={currencySymbol}
          data-test-menu-id={selectedMenu.id}
          name="prices"
          type="number"
          className="no-zoom"
        />
      </Form.Item>
    );
  };

  const renderPriceItem = (price) => {
    const initialValue = getDisplayPrice({
      priceInPence: price,
      withCurrencySymbol: false,
      currencyConfig,
    });
    return (
      <Form.Item
        key={`price`}
        label={capitalizeText(`price`)}
        name={`price`}
        initialValue={initialValue}
        data-test-id="menu-edit-form-input"
        rules={[{ required: true, message: "Please input price!" }]}
      >
        <Input
          prefix={currencySymbol}
          data-test-menu-id={price}
          name="price"
          type="number"
          className="no-zoom"
        />
      </Form.Item>
    );
  };

  const renderFormItem = (key) => (
    <Form.Item
      key={key}
      label={key}
      name={key}
      initialValue={selectedMenu[key]}
      data-test-id="menu-edit-form-input"
      rules={[
        { required: key !== "description", message: `Please input ${key}!` },
      ]}
    >
      <Input
        prefix={key === "price" ? currencySymbol : ""}
        data-test-menu-id={selectedMenu.id}
        name={key}
        type={key === "price" ? "number" : "text"}
        className="no-zoom"
      />
    </Form.Item>
  );
  const getFormItems = () => {
    return menuFields.flatMap((key) => {
      if (key === "locationPrices") {
        return selectedMenu.locationPrices.map(renderLocationPriceItem);
      }

      if (key === "price") {
        return renderPriceItem(selectedMenu[key]);
      }
      return renderFormItem(key);
    });
  };

  return (
    <>
      <div className="modal" role="dialog" aria-labelledby="edit-menu-title">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="edit-menu-title">
              Edit Menu Item
            </h2>
            <button
              type="button"
              className="modal-close"
              aria-label="Close modal"
              onClick={handleCancel}
            >
              <svg
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: false }}
              onFinish={onFinish}
              autoComplete="off"
              data-test-id="menu-edit-form"
            >
              {getFormItems()}
            </Form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              id="submitButton"
              onClick={form.submit}
              disabled={confirmLoading}
            >
              {confirmLoading && <SyncOutlined spin />}
              <span id="buttonText">
                {confirmLoading ? "Saving..." : "Save Changes"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuModal;
