import React, { useState } from "react";
import { Input } from "antd";
import Icon from "../../../../components/Icon";
import MenusFilter from "../MenusFilter";
import { getMenuFilterOptions } from "../../Utils";
import styles from "./index.module.css";

const MenusHeader = ({ onSearch, menus, onCategoryChanged }) => {
  const [menuFilter, setMenuFilter] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleFilterChange = (filter) => {
    setMenuFilter(filter !== "All" ? filter : null);
    setSearchTerm("");
    onCategoryChanged(filter);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
    setIsFilterVisible(false);
    setMenuFilter(null);
    onSearch(value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevState) => !prevState);
    setSearchTerm("");
    setMenuFilter(null);
  };
  return (
    <header className={styles.container} data-test-id="menus-header">
      <div className={styles.search_container}>
        <Input
          placeholder="Search menu"
          size="large"
          value={searchTerm}
          data-test-id="header-search-input"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <button onClick={toggleFilterVisibility} className={styles.button}>
          <Icon
            id="filter"
            data-test-id="filter-icon"
            className={styles.menu_filter_icon}
          />
        </button>
      </div>
      <MenusFilter
        showFilter={isFilterVisible}
        options={getMenuFilterOptions(menus)}
        selected={menuFilter}
        handleChange={handleFilterChange}
      />
    </header>
  );
};

export default MenusHeader;
