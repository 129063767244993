import React from "react";
import OrderDetails from "../OrderDetails";

const ListOrders = ({ orders, updateOrder, updatePaymentStatus, partner }) => {
  const getOrders = (orders, updateOrder, partner) => {
    return orders.map((order, index) => (
      <OrderDetails
        key={index}
        order={order}
        updateOrder={updateOrder}
        updatePaymentStatus={updatePaymentStatus}
        partner={partner}
      />
    ));
  };

  return (
    <div className="orders-list">
      {getOrders(orders, updateOrder, partner)}
      {orders.length === 0 && (
        <p style={{ textAlign: "center", margin: "30px" }}>{`No orders`}</p>
      )}
    </div>
  );
};

export default ListOrders;
