import React from "react";

const OrderActions = ({
  refresh,
  selectedDay,
  handleDateChange,
  onDownloadClicked,
  availableOrdersLength,
  downloading,
}) => {
  console.log({ downloading });
  return (
    <div className="orders-actions">
      <button
        type="button"
        className="btn-icon"
        aria-label="Refresh orders"
        onClick={refresh}
      >
        <svg
          width="20"
          height="20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </button>
      <select
        className="filter-select"
        value={selectedDay}
        onChange={(e) => handleDateChange(e.target.value)}
      >
        <option value="today">Today</option>
        <option value="yesterday">Yesterday</option>
        <option value="last7days">Last 7 days</option>
        <option value="last14days">Last 14 days</option>
        <option value="last30days">Last 30 days</option>
        <option value="last90days">Last 90 days</option>
      </select>
      <button
        type="button"
        className="btn-primary"
        onClick={onDownloadClicked}
        disabled={availableOrdersLength === 0}
      >
        {downloading && (
          <svg
            className="spinner"
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
        )}

        {!downloading && (
          <svg
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            ></path>
          </svg>
        )}
        {downloading ? "downloading" : "Download Report"}
      </button>
    </div>
  );
};

export default OrderActions;
