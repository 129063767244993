import React from "react";
import { getDisplayPrice } from "../../Utils";
import { capitalizeText } from "../../../../utils";

const getPriceItems = (menu, currencyConfig) => {
  if (menu.locationPrices) {
    return menu.locationPrices.map((locationPrice) => {
      return (
        <div
          className="menu-item-price"
          key={locationPrice.location}
          data-test-id="menu-price"
        >
          <span>Price @{capitalizeText(locationPrice.location)}: </span>
          <span>
            {getDisplayPrice({
              priceInPence: locationPrice.price,
              withCurrencySymbol: true,
              currencyConfig,
            })}
          </span>
        </div>
      );
    });
  }
  return (
    <div className="menu-item-price">
      <span>Price</span>
      <span>
        {" "}
        {getDisplayPrice({
          priceInPence: menu.price,
          withCurrencySymbol: true,
          currencyConfig,
        })}
      </span>
    </div>
  );
};

const ListMenusComponent = ({
  menus = [],
  onEditMenuClicked,
  currencyConfig,
  onMenuDeleteClicked,
}) => {
  return (
    <div className="menu-list">
      {menus.map((menu, index) => {
        const { id, name, description, locationPrices } = menu;
        console.log({ id, name, description, locationPrices });
        return (
          <article
            className="menu-item"
            key={index}
            data-test-id={`menu-item-${id}`}
          >
            <div className="menu-item-header">
              <div className="menu-item-title-wrapper">
                <h3 className="menu-item-title">{capitalizeText(name)}</h3>
              </div>
              <div className="menu-item-actions">
                <button
                  type="button"
                  className="menu-item-action delete"
                  aria-label="Delete item"
                  onClick={() => onMenuDeleteClicked(menu)}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    ></path>
                  </svg>
                </button>
                <button
                  type="button"
                  className="menu-item-action"
                  aria-label="Edit item"
                  onClick={() => onEditMenuClicked(menu)}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <p className="menu-item-description">
              {capitalizeText(description || "")}
            </p>
            <div className="menu-item-prices">
              {getPriceItems(menu, currencyConfig)}
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default ListMenusComponent;
