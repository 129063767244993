import React from "react";

const OrderFilters = ({
  selectedOrderStatus,
  handleStatusChange,
  availableOrdersLength,
}) => {
  return (
    <div className="orders-filters">
      <select
        className="filter-select"
        onChange={(e) => handleStatusChange(e.target.value)}
        disabled={availableOrdersLength === 0}
        value={selectedOrderStatus}
      >
        <option value="">Filter by Status</option>
        <option value="placed">Placed</option>
        <option value="confirmed">Confirmed</option>
        <option value="transit">Transit</option>
        <option value="delivered">Delivered</option>
        <option value="cancelled">Cancelled</option>
      </select>
    </div>
  );
};

export default OrderFilters;
