import React, { useContext, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "../Navigation";
import { AppContext } from "../../Router";

const getPartnerName = (partner, userAttributes) => {
  let name = partner.partnerName.toUpperCase();

  if (userAttributes.admin) {
    name += " - Admin";
  }

  if (userAttributes["custom:location"]) {
    name += ` - ${userAttributes["custom:location"].toUpperCase()}`;
  }

  return name;
};

const AppHeader = () => {
  const { partner, userAttributes } = useContext(AppContext);
  const [title, setTitle] = useState("");
  const location = useLocation();

  const getActiveRouteName = () => {
    const path = location.pathname;
    const pathArray = path.split("/");
    const route = pathArray[1];
    if (route === "") {
      return "Dashboard";
    }
    return `Manage ${route.charAt(0).toUpperCase()}${route.slice(1)}`;
  };

  // Ensure hooks are always called
  const memoizedTitle = useMemo(() => {
    if (partner && userAttributes) {
      return getPartnerName(partner, userAttributes);
    }
    return "";
  }, [partner, userAttributes]);

  useEffect(() => {
    if (memoizedTitle) {
      setTitle(memoizedTitle);
    }
  }, [memoizedTitle]);

  // Render loading or null state if necessary
  if (!partner || !userAttributes) {
    return null;
  }

  return (
    <header className="app-header">
      <Navigation />
      <div className="header-content">
        <h1 data-test-id="route-name">{getActiveRouteName()}</h1>
        <p data-test-id="partner-name">{title}</p>
      </div>
    </header>
  );
};

export default AppHeader;
